import React from 'react';
import * as PropTypes from 'prop-types';
import * as styles from './contentfulText.module.scss';

const ContentfulText = ({ textObj, className, innerClassName, dropcap }) => {
  const innerClass = dropcap
    ? `${styles[innerClassName]} ${styles.dropcap}`
    : styles[innerClassName];

  return textObj ? (
    <div className={appendClass('contentfulText', styles[className])}>
      <div
        dangerouslySetInnerHTML={{ __html: appendTargetBlank(textObj.childMarkdownRemark.html) }}
        className={appendClass('inner', innerClass)}
      />
    </div>
  ) : (
    <div />
  );
};

function appendClass(defaultClass, className) {
  return className ? [defaultClass, className].join(' ') : defaultClass;
}

function appendTargetBlank(text) {
  return text.replace('<a ', '<a target="_blank" rel="noreferrer"');
}

ContentfulText.propTypes = {
  textObj: PropTypes.object.isRequired,
  className: PropTypes.string,
  innerclassName: PropTypes.string,
};

export default ContentfulText;
