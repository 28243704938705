import React from 'react';
import * as styles from './twitter.module.scss';

const Twitter = () => (
  <svg
    className={styles.twitter}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18">
    <path
      fillRule="nonzero"
      d="M16.1366803,5.65624128 C16.1601179,5.74999124 16.1718364,5.90233445 16.1718364,6.11327144 C16.1718364,7.80076742 15.761681,9.42966961 14.9413706,10.9999785 C14.0976226,12.6640369 12.9140315,13.9882527 11.3905978,14.9726253 C9.72653949,16.074185 7.81638744,16.6249651 5.66014276,16.6249651 C3.59764749,16.6249651 1.7109336,16.074185 0,14.9726253 C0.257811707,14.9960629 0.550780116,15.0077815 0.878904155,15.0077815 C2.58983775,15.0077815 4.12498999,14.480439 5.48436192,13.425754 C4.66405156,13.425754 3.94335015,13.1855204 3.3222577,12.7050526 C2.70116526,12.2245848 2.27343226,11.621071 2.03905764,10.89451 C2.27343226,10.9179477 2.4960878,10.9296662 2.7070248,10.9296662 C3.03514883,10.9296662 3.36327341,10.89451 3.69139745,10.8241977 C2.84764946,10.6366983 2.14452614,10.2031053 1.58202748,9.52341956 C1.01952882,8.84373386 0.73827949,8.07029821 0.73827949,7.20311259 L0.73827949,7.13280026 C1.25390344,7.43748721 1.80468302,7.60154897 2.3906193,7.62498659 C1.89843297,7.27342493 1.4999966,6.82811331 1.19530965,6.28905227 C0.890622698,5.74999124 0.73827949,5.15819542 0.73827949,4.51366588 C0.73827949,3.86913635 0.914060321,3.25390344 1.26562198,2.66796716 C2.1796823,3.8164021 3.29882008,4.73046242 4.62303585,5.41014812 C5.94725162,6.08983382 7.35935728,6.4648331 8.85935388,6.53514544 C8.81247917,6.25389611 8.78904155,5.97264678 8.78904155,5.69139745 C8.78904155,5.03514883 8.95310383,4.41991593 9.28122787,3.84569872 C9.60935191,3.27148152 10.0546635,2.82031037 10.6171622,2.49218633 C11.1796608,2.16406229 11.7890342,2 12.4452828,2 C12.9843439,2 13.4823897,2.1054685 13.9394199,2.3164055 C14.3964501,2.52734249 14.800746,2.80859182 15.1523076,3.16015348 C15.9960556,2.9960912 16.7812098,2.70312332 17.5077708,2.28124933 C17.2265214,3.14843494 16.6874604,3.82812064 15.8905871,4.32030697 C16.5937104,4.22655701 17.2968338,4.0273391 17.9999571,3.72265214 C17.4843331,4.47265018 16.8632412,5.11718025 16.1366803,5.65624128 Z"
    />
  </svg>
);

export default Twitter;
