import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

class Image extends Component {
  state = {
    isLoaded: false,
  };

  render() {
    const { isLoaded } = this.state;
    const { style, className, fluid, alt } = this.props;

    return (
      <span>
        {!isLoaded && <div className={className} style={{ backgroundColor: '#CCC' }} />}
        <img
          style={style}
          className={className}
          srcSet={fluid ? fluid.srcSet : ''}
          src={fluid ? fluid.src : ''}
          alt={alt}
          onLoad={() => this.setState({ isLoaded: true })}
          style={isLoaded ? { visibility: 'visible' } : { visibility: 'hidden' }} // eslint-disable-line
        />
      </span>
    );
  }
}

Image.propTypes = {
  fluid: PropTypes.object.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  alt: PropTypes.string,
};

export default Image;
