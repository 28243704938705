import React from 'react';
import { Link, graphql } from 'gatsby';
import * as PropTypes from 'prop-types';

import ContentfulText from '../../ContentfulText';
import Button from '../../Button';
import { RelatedPress, RelatedThoughts } from '../../RelatedMedia';
import Social from '../../Social';

import * as styles from './content.module.scss';

const Content = ({
  slug,
  description,
  featuredPress,
  allPress,
  featuredThoughts,
  allThoughts,
  buttonUrl,
  buttonText,
  quote,
  twitter,
  linkedIn,
  facebook,
  crunchbase,
  theme,
}) => (
  <div className={styles.content}>
    {quote && <h3>"{quote.quote}"</h3>}

    <ContentfulText classNames={['description']} textObj={description} />

    {(featuredPress || allPress) && (
      <div className={styles.related}>
        {featuredPress && <RelatedPress press={featuredPress} />}
        {allPress && (
          <Link className={styles.moreLink} to={`${slug}/press`}>
            See all Related Press
          </Link>
        )}
      </div>
    )}

    {(featuredThoughts || allThoughts) && (
      <div className={styles.related}>
        {featuredThoughts && <RelatedThoughts thought={featuredThoughts} />}
        {allThoughts && (
          <Link className={styles.moreLink} to={`${slug}/thoughts`}>
            See all Related Thoughts
          </Link>
        )}
      </div>
    )}

    {buttonUrl && (
      <div className={styles.cta}>
        <Button type="external" theme="white" text={buttonText} url={buttonUrl} />
      </div>
    )}

    {(twitter || facebook || linkedIn) && (
      <div className={styles.social}>
        <Social
          twitter={twitter}
          linkedIn={linkedIn}
          facebook={facebook}
          crunchbase={crunchbase}
          theme={theme}
        />
      </div>
    )}
  </div>
);

Content.propTypes = {
  slug: PropTypes.string,
  description: PropTypes.object.isRequired,
  featuredPress: PropTypes.array,
  allPress: PropTypes.array,
  featuredThoughts: PropTypes.array,
  allThoughts: PropTypes.array,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  quote: PropTypes.object,
  twitter: PropTypes.string,
  linkedIn: PropTypes.string,
  facebook: PropTypes.string,
  crunchbase: PropTypes.string,
  theme: PropTypes.string,
};

export default Content;

export const CompanyQuery = graphql`
  fragment CompanyContent on ContentfulCompany {
    ...CompanySocial
    description {
      childMarkdownRemark {
        html
      }
    }
    buttonText
    buttonUrl
    featuredPress {
      title
      url
    }
    quote {
      quote
    }
  }
`;

export const PersonQuery = graphql`
  fragment PersonContent on ContentfulPerson {
    ...PersonSocial
    description {
      childMarkdownRemark {
        html
      }
    }
    buttonText
    buttonUrl
    featuredPress {
      title
      url
    }
    featuredThoughts {
      title
      slug
    }
  }
`;
