import React from 'react';
import * as styles from './facebook.module.scss';

const Facebook = () => (
  <svg
    className={styles.facebook}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18">
    <path
      fillRule="nonzero"
      d="M6.7070248,17.9999571 L6.7070248,9.94919503 L4,9.94919503 L4,6.74998391 L6.7070248,6.74998391 L6.7070248,4.21873994 C6.7070248,2.88280563 7.08202408,1.84569872 7.83202211,1.10741923 C8.58202015,0.369139745 9.57811188,0 10.8202962,0 C11.8281065,0 12.6484169,0.0468747094 13.2812279,0.140624665 L13.2812279,2.98827413 L11.5937319,2.98827413 C10.9609209,2.98827413 10.5273284,3.12889879 10.2929537,3.41014812 C10.1054544,3.64452274 10.0117044,4.01952149 10.0117044,4.53514544 L10.0117044,6.74998391 L12.9999785,6.74998391 L12.5781046,9.94919503 L10.0117044,9.94919503 L10.0117044,17.9999571 L6.7070248,17.9999571 Z"
    />
  </svg>
);

export default Facebook;
