import React from 'react';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';

import Facebook from '../../svgs/Facebook';
import LinkedIn from '../../svgs/LinkedIn';
import Twitter from '../../svgs/Twitter';
import CrunchBase from '../../svgs/CrunchBase';

import * as styles from './social.module.scss';


const Social = ({ twitter, linkedIn, facebook, crunchbase, theme }) => (
  <ul className={[styles.social, styles[theme]].join(' ')}>
    {facebook && (
      <li>
        <a aria-label="Facebook" href={facebook} target="_blank" rel="noreferrer" className={styles.link}>
          <Facebook />
        </a>
      </li>
    )}

    {twitter && (
      <li>
        <a aria-label="Twitter" href={twitter} target="_blank" rel="noreferrer" className={styles.link}>
          <Twitter />
        </a>
      </li>
    )}

    {linkedIn && (
      <li>
        <a aria-label="LinkedIn" href={linkedIn} target="_blank" rel="noreferrer" className={styles.link}>
          <LinkedIn />
        </a>
      </li>
    )}

    {crunchbase && (
      <li>
        <a aria-label="Crunchbase" href={crunchbase} target="_blank" rel="noreferrer" className={styles.link}>
          <CrunchBase />
        </a>
      </li>
    )}
  </ul>
);

Social.propTypes = {
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  linkedIn: PropTypes.string,
  theme: PropTypes.string,
};

export default Social;

export const companyQuery = graphql`
  fragment CompanySocial on ContentfulCompany {
    facebook
    twitter
    linkedIn
    crunchbase
  }
`;

export const personQuery = graphql`
  fragment PersonSocial on ContentfulPerson {
    twitter
    linkedIn
  }
`;
