import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import StagingNotice from '../components/StagingNotice';
import 'normalize.css';
import '../styles/base/_base.scss';

const TemplateWrapper = ({ children }) => {
  return (
    <main className="overflow-all modal-layout">
      <StaticQuery
        query={graphql`
          {
            site {
              siteMetadata {
                netlifyBuildTime
              }
            }
          }
        `}
        render={data => (
          <>
            {data.site.siteMetadata.netlifyBuildTime && (
              <StagingNotice
                netlifyBuildTime={data.site.siteMetadata.netlifyBuildTime}
                layout="modal"
              />
            )}
            {data.site.siteMetadata.netlifyBuildTime && (
              <Helmet>
                <meta name="robots" content="noindex" />
              </Helmet>
            )}
            {children}
          </>
        )}
      />
    </main>
  );
};

TemplateWrapper.propTypes = {
  children: PropTypes.object,
};

export default TemplateWrapper;
