import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import Seo from '../../components/SEO';
import Close from '../../svgs/Close';
import Image from '../../components/Image';
import ModalContent from '../../components/Modal/Content';
import ModalImagery from '../../components/Modal/Imagery';
import MetaList from './CompanyModal/MetaList';
import Layout from '../../layouts/modal';
import * as styles from '../../components/Modal/modal.module.scss';

class CompanyTemplate extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  componentDidMount() {
    document.addEventListener('keydown', this.catchEscKey, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.catchEscKey, false);
  }

  catchEscKey = evt => {
    if (evt.keyCode === 27) {
      this.goBack();
    }
  };

  goBack = () => {
    if (this.props.location.state && this.props.location.state.referrer) {
      return navigate(-1);
    }
    return navigate('/portfolio');
  };

  render() {
    const { transition, data } = this.props;
    const { contentfulCompany: company, site } = data;

    const containerStyles = {
      opacity: 0,
      ...(transition ? transition.style : {}),
    };

    return (
          <Layout>
            <div className={[styles.modal, styles.companyModal].join(' ')} style={containerStyles}>
              <Seo
                siteData={site}
                defaultTitle={`${company.name} - Funded by Emergence `}
                pathName={`/portfolio/${company.slug}`}
              />
              <div className={styles.background} />
              <button aria-label="Go back" className={styles.close} onClick={this.goBack}>
                <Close />
              </button>
              <header className={styles.header}>
                <a className={styles.titleLink} href={company.website} target="_blank" rel="noreferrer">
                  {company.logoWhite && company.logoWhite.fluid ? (
                    <Image
                      className={styles.logo}
                      fluid={company.logoWhite.fluid}
                      alt={`${company.name} Logo`}
                    />
                  ) : (
                    <h1 className={styles.title}>{company.name}</h1>
                  )}

                  {company.founder && (
                    <h5 className={styles.subtitle}>
                      <strong>{company.founder}</strong>
                      {company.founderTitle && <span>, {company.founderTitle}</span>}
                    </h5>
                  )}
                </a>
              </header>
              <div className={styles.meta}>
                <MetaList company={company} theme="emerald" />
              </div>
              <div className={styles.content}>
                <ModalContent
                  slug={`/portfolio/${company.slug}`}
                  description={company.description}
                  featuredPress={company.featuredPress}
                  allPress={company.press}
                  buttonUrl={company.buttonUrl}
                  buttonText={company.buttonText}
                  quote={company.quote}
                  twitter={company.twitter}
                  linkedIn={company.linkedIn}
                  facebook={company.facebook}
                  crunchbase={company.crunchbase}
                  theme="emerald"
                />
              </div>
              {company.founderHeadshot && (
                <div className={styles.imagery}>
                  <ModalImagery headshot={company.founderHeadshot} modalType="company" />
                </div>
              )}
            </div>
          </Layout>
    );
  }
}
export default CompanyTemplate;

export const pageQuery = graphql`
  query companyQuery($id: String!) {
    contentfulCompany(id: { eq: $id }) {
      name
      slug
      website
      press {
        id
      }
      founder
      founderTitle
      logoWhite {
        fluid {
          src
          srcSet
        }
      }
      ...CompanyContent
      ...CompanyImagery
      ...CompanyMetaList
    }
    ...SiteQuery
  }
`;
