import React from 'react';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';
import * as styles from './button.module.scss';

const Button = ({ url, text, type, theme, disabled }) => {
  return (
    <div
      className={[styles.buttonWrapper, styles[theme], disabled ? styles.disabled : ''].join(' ')}>
      {link(type, url, text)}
    </div>
  );
};

Button.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  theme: PropTypes.string,
  disabled: PropTypes.bool,
};

function link(type, url, text) {
  if (type === 'external') {
    return (
      <a href={url} className={styles.button} target="_blank" rel="noreferrer">
        <span>{text}</span>
      </a>
    );
  } else if (type === 'newsletter') {
    return (
      <div className={styles.button}>
        <input
          className={styles.input}
          type="submit"
          value="Subscribe"
          name="subscribe"
          id="mc-embedded-subscribe"
        />
        Subscribe
      </div>
    );
  } else if (type === 'anchor') {
    return <span className={styles.button}>{text}</span>;
  } else {
    return (
      <Link to={url} className={styles.button}>
        {text}
      </Link>
    );
  }
}

export default Button;
