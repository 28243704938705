import React from 'react';
import { Link, graphql } from 'gatsby';
import * as PropTypes from 'prop-types';

import * as metaListStyles from '../../../../components/Modal/metaList.module.scss';
import * as styles from './metaList.module.scss';

const MetaList = ({ company, theme }) => (
  <dl className={[metaListStyles.list, styles.list, styles[theme]].join(' ')}>
    {company.emergencePartners && (
      <div className={styles.item}>
        <dt className={styles.label}>
          {company.emergencePartners.length > 1 ? 'Investors' : 'Investor'}
        </dt>
        <dd className={styles.value}>
          {company.emergencePartners.map((ep, i) => (
            <span key={i} className={styles.valueItem}>
              <Link to={`/people/${ep.slug}`}>{ep.name}</Link>
            </span>
          ))}
        </dd>
      </div>
    )}
    {company.thesis && (
      <div className={styles.item}>
        <dt className={styles.label}>{company.thesis.length > 1 ? 'Theses' : 'Thesis'}</dt>
        <dd className={styles.value}>
          {company.thesis.map((t, i) => (
            <span key={i} className={styles.valueItem}>
              <Link to={`/${t.slug}`}>{t.name}</Link>
            </span>
          ))}
        </dd>
      </div>
    )}
    {company.founded && (
      <div className={styles.item}>
        <dt className={styles.label}>Founded</dt>
        <dd className={styles.value}>{company.founded}</dd>
      </div>
    )}
    {company.partnered && (
      <div className={styles.item}>
        <dt className={styles.label}>Partnered</dt>
        <dd className={styles.value}>{company.partnered}</dd>
      </div>
    )}
    {company.status && (
      <div className={styles.item}>
        <dt className={styles.label}>Status</dt>
        <dd className={styles.value}>{company.status}</dd>
      </div>
    )}
  </dl>
);

MetaList.propTypes = {
  company: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
};

export default MetaList;

export const query = graphql`
  fragment CompanyMetaList on ContentfulCompany {
    emergencePartners {
      name
      slug
    }
    thesis {
      name
      slug
    }
    status
    founded
    partnered
  }
`;
