import React from 'react';
import * as PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Image from '../../Image';

import * as styles from './imagery.module.scss';

const Imagery = ({ headshot, modalType }) => (
  <div className={[styles.imagery, styles[modalType]].join(' ')}>
    <figure className={'imageWrapper'}>
      <Image className={styles.image} fluid={headshot.fluid} />
    </figure>
  </div>
);

Imagery.propTypes = {
  headshot: PropTypes.object.isRequired,
  modalType: PropTypes.string.isRequired,
};

export default Imagery;

export const companyQuery = graphql`
  fragment CompanyImagery on ContentfulCompany {
    founderHeadshot {
      fluid {
        src
        srcSet
      }
    }
  }
`;

export const personQuery = graphql`
  fragment PersonImagery on ContentfulPerson {
    portrait {
      fluid {
        src
        srcSet
      }
    }
  }
`;
