import React from 'react';
import * as styles from './linkedIn.module.scss';

const LinkedIn = () => (
  <svg
    className={styles.linkedIn}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18">
    <path
      fillRule="nonzero"
      d="M4.51561662,15.7499624 L1.24609316,15.7499624 L1.24609316,5.23826876 L4.51561662,5.23826876 L4.51561662,15.7499624 Z M2.89843297,3.79686595 C2.38280902,3.79686595 1.93749794,3.60936657 1.56249866,3.23436729 C1.18749937,2.859368 1,2.41405692 1,1.89843297 C1,1.38280902 1.18749937,0.937497944 1.56249866,0.562498659 C1.93749794,0.187499374 2.38280902,0 2.89843297,0 C3.41405692,0 3.859368,0.187499374 4.23436729,0.562498659 C4.60936657,0.937497944 4.79686595,1.38280902 4.79686595,1.89843297 C4.79686595,2.41405692 4.60936657,2.859368 4.23436729,3.23436729 C3.859368,3.60936657 3.41405692,3.79686595 2.89843297,3.79686595 Z M16.7499624,15.7499624 L13.480439,15.7499624 L13.480439,10.6171622 C13.480439,9.7734142 13.4101267,9.16404083 13.269502,8.78904155 C13.0116903,8.15623055 12.5077849,7.83982506 11.7577869,7.83982506 C11.0077888,7.83982506 10.4804463,8.12107439 10.1757594,8.68357305 C9.94138475,9.10544704 9.82419771,9.72653949 9.82419771,10.5468499 L9.82419771,15.7499624 L6.58983042,15.7499624 L6.58983042,5.23826876 L9.71872921,5.23826876 L9.71872921,6.67967157 L9.75388538,6.67967157 C9.98826,6.21092287 10.3632587,5.82420504 10.8788827,5.51951809 C11.4413814,5.14451881 12.09763,4.95701943 12.847628,4.95701943 C14.3710617,4.95701943 15.4374658,5.43748721 16.0468391,6.39842224 C16.5155878,7.1718579 16.7499624,8.36716755 16.7499624,9.9843512 L16.7499624,15.7499624 Z"
    />
  </svg>
);

export default LinkedIn;
