import React from 'react';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';
import {stripHtml} from "../../../lib/helpers";
import * as styles from './relatedMedia.module.scss';

export const RelatedPress = ({ press }) => (
  <div className={styles.related}>
    <h5 className={styles.title}>Related Press</h5>
    <ul className={styles.list}>
      {press.map((p, i) => {
        return (
          <li key={i} className={styles.item}>
            <a href={p.url} className={styles.link} target="_blank" rel="noreferrer">
              {stripHtml(p.title)}
            </a>
          </li>
        );
      })}
    </ul>
  </div>
);

RelatedPress.propTypes = {
  press: PropTypes.array.isRequired,
};

export const RelatedThoughts = ({ thought }) => (
  <div className={styles.related}>
    <h5 className={styles.title}>Related Thoughts</h5>
    <ul className={styles.list}>
      {thought.map((t, i) => {
        return (
          <li key={i} className={styles.item}>
            <Link to={`/thoughts/${t.slug}`} className={styles.link}>
              {stripHtml(t.title)}
            </Link>
          </li>
        );
      })}
    </ul>
  </div>
);

RelatedThoughts.propTypes = {
  thought: PropTypes.array.isRequired,
};
