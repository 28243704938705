// extracted by mini-css-extract-plugin
export var modal = "modal-module--modal--3H93I";
export var background = "modal-module--background--1ecjK";
export var companyModal = "modal-module--companyModal--3ZZ8p";
export var personModal = "modal-module--personModal--1dlES";
export var close = "modal-module--close--GAzpc";
export var header = "modal-module--header--28BPL";
export var meta = "modal-module--meta--24cXO";
export var imagery = "modal-module--imagery--1uaaw";
export var content = "modal-module--content--3DM4N";
export var logo = "modal-module--logo--2K6SQ";
export var titleLink = "modal-module--titleLink--7T1Nb";
export var title = "modal-module--title--1sowr";
export var subtitle = "modal-module--subtitle--16WV6";
export var assistant = "modal-module--assistant--32nHX";